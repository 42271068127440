
import "../App.css";

import { Button, Group } from "@mantine/core";


import Swal from 'sweetalert2'

function S8(props) {

  const onCheck = () => {
    Swal.fire("Good!", "You finish what you started", "success")
    props.next()
  }

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>You look like an alfa man.</p>
        <Group>
          <Button color="teal" size="xl" onClick={() => props.youGay()}>
            Skip test
          </Button>
          <Button color="red" size="xl" onClick={() => onCheck()}>
            Next
          </Button>
        </Group>
      </header>
    </div>
  );
}

export default S8;
