import { useEffect } from "react";
import "../App.css";

import { Dialog, Group, Button, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";


import Swal from 'sweetalert2'

function S6(props) {
  const [opened, { toggle, close }] = useDisclosure(false);
  
  const start = () => {
    setTimeout(() => {
      toggle()
    }, 1000)
  }

  useEffect(() => {
    start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onCheck = (option) => {
    if (!option) {
      props.youGay();
      close();
    } else {
      Swal.fire("Good!", "You don't need me to tell you why you're not gay, but this is my game and nobody tells me what to do, so feedback stays.", "success")
      props.next()
      close();
    }
  };

  return (
    <>
      <Dialog
        opened={opened}
        withCloseButton={false}
        onClose={close}
        size="lg"
        radius="md"
      >
        <Text size="xl" mb="xs" weight={500}>
          Want to hide positive feedback messages?
        </Text>

        <Group>
          <Button variant="light" color="teal" onClick={() => onCheck(true)}>
            Yes, hide
          </Button>
          <Button variant="light" color="red" onClick={() => onCheck(false)}>
            No, keep it
          </Button>
        </Group>
      </Dialog>
    </>
  );
}

export default S6;
