import { useState } from "react";
import logo from "../rainbow.png";
import "../App.css";

import { Button } from "@mantine/core";

import Swal from 'sweetalert2'

function S1(props) {
  const [content, setContent] = useState("Weclome");

  const handleContentChange = (event) => {
    setContent(event.target.textContent.trim());
  };

  const isValid = () => {
    return content === "Welcome"
  }

  const onCheck = () => {
    if (!isValid()) {
      props.youGay();
    } else {
      Swal.fire("Good!", "You don't tolerate mistakes", "success")
      props.next();
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Only 1% passes!</p>
        <p>
          <span contentEditable="true" onInput={handleContentChange}>
            Weclome
          </span>{" "}
          to the Gay Test
        </p>
        <Button color={isValid() ? "blue" : "pink"} size="xl" onClick={() => onCheck()}>
          Begin {!isValid() && "🫶"}
        </Button>
      </header>
    </div>
  );
}

export default S1;
