import { useEffect } from "react";
import "../App.css";

import { Dialog, Group, Button, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";


import Swal from 'sweetalert2'

function S5(props) {
  const [opened, { toggle, close }] = useDisclosure(false);
  
  const start = () => {
    setTimeout(() => {
      toggle()
    }, 1000)
  }

  useEffect(() => {
    start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onCheck = (option) => {
    if (!option) {
      props.youGay();
      close()
    } else {
      Swal.fire("Good!", "You're no pussy", "success")
      props.next()
      close()
    }
  };

  return (
    <>
      <Dialog
        opened={opened}
        withCloseButton={false}
        onClose={close}
        size="lg"
        radius="md"
      >
        <Text size="xl" mb="xs" weight={500}>
          Continue in Dark Mode to avoid eye strain? 😎
        </Text>

        <Group>
          <Button variant="light" color="dark" onClick={() => onCheck(false)}>Dark mode 🌙</Button>
          <Button variant="light" color="yellow" onClick={() => onCheck(true)}>Light mode ☀️</Button>
        </Group>
      </Dialog>
    </>
  );
}

export default S5;
