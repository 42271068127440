import { useEffect, useState, useRef } from "react";
import "../App.css";

import { Button, Group } from "@mantine/core";

function S9(props) {
  const [text, setText] = useState("");
  // const winText = useRef("");
  const timeoutId = useRef();

  // useEffect(() => {
  //   document.body.addEventListener("keydown", win);

  //   return () => {
  //     document.body.removeEventListener("keydown", win);
  //   };
  // });

  useEffect(() => {
    if (!timeoutId.current) {
      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        setText("");
      }, 5000);
    }
    if (text.toLowerCase() === "FUCK OFF!".toLowerCase()) {
      props.next();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  const openKeyBoard = () => {
    const hiddenInput = document.getElementById("hiddenInput");
    hiddenInput.focus();
  };

  const getOpacity = () => {
    if (text.toLowerCase() === "f"){
      return 0.9
    }
    if (text.toLowerCase() === "fu"){
      return 0.8
    }
    if (text.toLowerCase() === "fuc"){
      return 0.7
    }
    if (text.toLowerCase() === "fuck"){
      return 0.6
    }
    if (text.toLowerCase() === "fuck "){
      return 0.5
    }
    if (text.toLowerCase() === "fuck o"){
      return 0.4
    }
    if (text.toLowerCase() === "fuck of"){
      return 0.3
    }
    if (text.toLowerCase() === "fuck off"){
      return 0.2
    }
    if (text.toLowerCase() === "fuck off!"){
      return 0.1
    }
    
  }

  // const win = (event) => {
  //   if (!timeoutId.current) {
  //     timeoutId.current = setTimeout(() => {
  //       timeoutId.current = null;
  //       winText.current = "";
  //       setText("");
  //     }, 5000);
  //   }
  //   console.log(event.key);
  //   if (winText.current === "" && (event.key === "f" || event.key === "F")) {
  //     winText.current = "F";
  //     setText("F");
  //   }
  //   if (winText.current === "F" && (event.key === "u" || event.key === "U")) {
  //     winText.current = "FU";
  //     setText("FU");
  //   }
  //   if (winText.current === "FU" && (event.key === "c" || event.key === "C")) {
  //     winText.current = "FUC";
  //     setText("FUC");
  //   }
  //   if (winText.current === "FUC" && (event.key === "k" || event.key === "K")) {
  //     winText.current = "FUCK";
  //     setText("FUCK");
  //   }
  //   if (winText.current === "FUCK" && event.key === " ") {
  //     winText.current = "FUCK ";
  //     setText("FUCK ");
  //   }
  //   if (
  //     winText.current === "FUCK " &&
  //     (event.key === "o" || event.key === "O")
  //   ) {
  //     winText.current = "FUCK O";
  //     setText("FUCK O");
  //   }
  //   if (
  //     winText.current === "FUCK O" &&
  //     (event.key === "f" || event.key === "F")
  //   ) {
  //     winText.current = "FUCK OF";
  //     setText("FUCK OF");
  //   }
  //   if (
  //     winText.current === "FUCK OF" &&
  //     (event.key === "f" || event.key === "F")
  //   ) {
  //     winText.current = "FUCK OFF";
  //     setText("FUCK OFF");
  //   }
  //   if (winText.current === "FUCK OFF" && event.key === "!") {
  //     winText.current = "FUCK OFF!";
  //     setText("FUCK OFF!");
  //     props.next();
  //   }
  // };

  return (
    <div
      className="App"
      style={{
        opacity: getOpacity(),
      }}
      onClick={() => openKeyBoard()}
    >
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>Test: Are you Gay?</p>
        <input
          autocomplete="off"
          value={text}
          type="text"
          id="hiddenInput"
          style={{ opacity: 0, position: "absolute" }}
          onChange={(event) => setText(event.currentTarget.value)}
        />
        <Group>
          <Button color="teal" size="xl" onClick={() => props.youGay()}>
            Yes
          </Button>
          <Button color="red" size="xl" onClick={() => props.youGay()}>
            No
          </Button>
        </Group>
      </header>
    </div>
  );
}

export default S9;
