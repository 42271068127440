import { useState, useRef } from "react";
import "../App.css";

import { Button, Select } from "@mantine/core";

import Swal from "sweetalert2";

function S7(props) {
  const [count, setCount] = useState(0);
  const [stop, setStop] = useState(false);
  const timeoutId = useRef();

  const onBypass = () => {
    if (!timeoutId.current) {
      timeoutId.current = setTimeout(() => {
        setCount(0);
        timeoutId.current = null;
      }, 5000);
    }
    setCount(count + 1);
    if (count >= 40) {
      setStop(true);
      setTimeout(() => {
        Swal.fire("Good!", "Resilience!", "success");
        props.next();
      }, 3000);
    }
  };

  const delayGay = () => {
    setTimeout(() => {
      props.youGay();
    }, 2000);
  };

  const getColor = () => {
    if (count === 0) {
      return "red";
    } else if (count < 3) {
      return "pink";
    } else if (count < 6) {
      return "grape";
    } else if (count < 15) {
      return "violet";
    } else if (count < 25) {
      return "indigo";
    } else if (count < 30) {
      return "cyan";
    } else if (count < 35) {
      return "teal";
    } else if (count >= 35) {
      return "green";
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}

        <Select
          onClick={() => delayGay()}
          size="xl"
          label="Choose your pronoun of preference"
          placeholder="Pick one"
          data={[
            { value: "They/Them", label: "They/Them" },
            { value: "Ze/Hir", label: "Ze/Hir" },
            { value: "Xe/Xem", label: "Xe/Xem" },
            { value: "Ey/Em", label: "Ey/Em" },
            { value: "Per/Per", label: "Per/Per" },
            { value: "Ve/Ver", label: "Ve/Ver" },
            { value: "Ze/Zir", label: "Ze/Zir" },
            { value: "Zhe/Zhim", label: "Zhe/Zhim" },
            { value: "Faer/Faer", label: "Faer/Faer" },
            { value: "Fae/Faer", label: "Fae/Faer" },
            { value: "Thon/Thon", label: "Thon/Thon" },
            { value: "None/None", label: "None/None" },
            { value: "He/Him", label: "He/Him" },
            { value: "She/Her", label: "She/Her" },
          ]}
        />
        {stop ? (
          <Button
            mt={20}
            color={"dark"}
            size="xl"
          >
            Stop
          </Button>
        ) : (
          <Button
            mt={20}
            color={getColor()}
            size="xl"
            onClick={() => onBypass()}
          >
            Continue
          </Button>
        )}
      </header>
    </div>
  );
}

export default S7;
