import { useState, useEffect } from "react";
// import CA1 from "../img/ca1.png";
import CA2 from "../img/ca2.png";
import "../App.css";

import { Button, TextInput, Text } from "@mantine/core";

import Swal from "sweetalert2";

function S12(props) {
  const [value1, setValue1] = useState("");
  const [text, setText] = useState("Write the character in the same order");

  useEffect(() => {
    setTimeout(() => {
      setText("Write the character in the inverse order")
    }, 5000)
  }, [])

  const isValid = () => {
    return value1 === "i46m1"
  }

  const onCheck = () => {
    if (isValid()){
      Swal.fire("Good!", "Patience!", "success")
      props.next()
    }
    else{
      props.youGay()
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Captcha: Are you human? <br />{" "}
          <Text size="sm" dimmed>
            {text}
          </Text>
        </p>
        <img src={CA2} alt="logo" style={{ border: "1px solid black" }} />
        <TextInput
          size="xl"
          placeholder=""
          label="Captcha"
          withAsterisk
          value={value1}
          onChange={(event) => setValue1(event.currentTarget.value)}
        />
        <Button
          mt={20}
          color={isValid() ? "teal" : "pink"}
          size="xl"
          onClick={() => onCheck()}
          disabled={value1 !== "1m64i" && !isValid()}
        >
          Continue
        </Button>
      </header>
    </div>
  );
}

export default S12;
