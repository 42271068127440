import "../App.css";

import { Button, Image } from "@mantine/core";

function S10() {
  const xLogo =
    "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fnews.topusainsights.com%2Fwp-content%2Fuploads%2F2023%2F07%2Ftwitter-x-logo.jpg&f=1&nofb=1&ipt=6b7834fcca7a0b03f50a75344eca2ea07af66afc2dcb1acf0e52f3d401118516&ipo=images";

  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Gay Test 🌈",
          text: "Are you gay?, are you not?, find out now! Only 1% passes!",
          url: "https://www.gaytest.co",
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      window.open(
        `https://twitter.com/intent/tweet?url=https://www.gaytest.co`,
        "_blank"
      );
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>Good, you don't answer stupid questions, now fuck off!</p>
        <Button
          color="dark"
          size="xl"
          onClick={() => onShare()}
          rightIcon={
            <Image src={xLogo} width={25} radius="xl" />
          }
        >
          Share {navigator.share && "🔗"}
        </Button>
      </header>
    </div>
  );
}

export default S10;
