import { useState } from "react";
import "../App.css";

import { Button, Paper, Container, ScrollArea, Title } from "@mantine/core";


import Swal from 'sweetalert2'

function S3(props) {
  const [content, setContent] = useState("I CERTIFY THAT I'M GAY");

  const handleContentChange = (event) => {
    setContent(event.target.textContent);
  };

  const isValid = () => {
    return content === "I CERTIFY THAT I'M NOT GAY";
  };

  const onCheck = () => {
    if (!isValid()) {
      props.youGay();
    } else {
      Swal.fire("Good!", "You don't let bullshit pass by", "success")
      props.next();
    }
  };

  return (
    <div className="App">
      <header>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <Container mt={100}>
          <Title order={1}>Terms and conditions</Title>
          <Paper shadow="xs" p="md">
            <ScrollArea style={{ height: "300px" }}>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Nulla posuere sollicitudin aliquam ultrices sagittis orci a
                scelerisque purus. Magna fringilla urna porttitor rhoncus dolor
                purus non. Est sit amet facilisis magna etiam tempor orci eu
                lobortis. Quis lectus nulla at volutpat. Auctor augue mauris
                augue neque gravida. Sagittis vitae et leo duis ut. Dignissim
                suspendisse in est ante in nibh mauris cursus. Augue ut lectus
                arcu bibendum at varius vel pharetra. Ornare lectus sit amet est
                placerat in egestas erat imperdiet. Malesuada proin libero nunc
                consequat interdum varius sit amet mattis. Erat pellentesque
                adipiscing commodo elit at. Id donec ultrices tincidunt arcu
                non. Consectetur adipiscing elit duis tristique sollicitudin
                nibh. Massa placerat duis ultricies lacus. Elementum integer
                enim neque volutpat ac. Fermentum odio eu feugiat pretium nibh
                ipsum. Mattis rhoncus urna neque viverra justo nec. Porta lorem
                mollis aliquam ut porttitor leo a diam sollicitudin.
              </p>
              <p>
                Volutpat blandit aliquam etiam erat velit scelerisque in dictum
                non. Aliquet enim tortor at auctor urna nunc. Amet justo donec
                enim diam vulputate ut pharetra sit. Tortor dignissim convallis
                aenean et tortor at risus viverra adipiscing. Viverra accumsan
                in nisl nisi scelerisque. Risus commodo viverra maecenas
                accumsan lacus vel. Mauris augue neque gravida in fermentum et
                sollicitudin ac. Quisque egestas diam in arcu cursus euismod
                quis viverra nibh. Phasellus egestas tellus rutrum tellus
                pellentesque eu. Iaculis nunc sed augue lacus viverra. Tortor id
                aliquet lectus proin nibh nisl.
              </p>
              <p>
                Maecenas volutpat blandit aliquam etiam erat velit scelerisque.
                Eget mi proin sed libero enim sed faucibus turpis in. Donec et
                odio pellentesque diam volutpat commodo sed egestas egestas. At
                risus viverra adipiscing at in. Nisi quis eleifend quam
                adipiscing vitae. Id diam maecenas ultricies mi eget mauris
                pharetra.{" "}
                <span contentEditable="true" onInput={handleContentChange}>
                  I CERTIFY THAT I'M GAY
                </span>
                . Vestibulum sed arcu non odio euismod lacinia. Velit egestas
                dui id ornare arcu. Scelerisque eleifend donec pretium
                vulputate.
              </p>
              <p>
                Facilisis sed odio morbi quis commodo. Purus in massa tempor nec
                feugiat nisl pretium fusce. Ipsum a arcu cursus vitae congue
                mauris rhoncus aenean. Urna neque viverra justo nec ultrices dui
                sapien. Vel pharetra vel turpis nunc eget lorem dolor. Porta
                lorem mollis aliquam ut porttitor leo a diam. Mi in nulla
                posuere sollicitudin. Semper risus in hendrerit gravida. Urna
                neque viverra justo nec ultrices. . Nullam eget felis eget nunc
                lobortis mattis aliquam faucibus. just fuck off!, 
                Consectetur a erat nam at
                lectus urna. Et leo duis ut diam quam nulla. Lectus quam id leo
                in vitae turpis. Ullamcorper morbi tincidunt ornare massa.
                Aliquam vestibulum morbi blandit cursus. Libero enim sed
                faucibus turpis in eu mi bibendum neque. Egestas quis ipsum
                suspendisse ultrices gravida dictum fusce ut placerat. Massa
                eget egestas purus viverra accumsan in nisl nisi. Adipiscing at
                in tellus integer feugiat scelerisque varius morbi enim.
              </p>
              <p>
                Sociis natoque penatibus et magnis. Lacus sed viverra tellus in
                hac habitasse platea dictumst vestibulum. Viverra ipsum nunc
                aliquet bibendum. Urna et pharetra pharetra massa massa
                ultricies mi. Tincidunt vitae semper quis lectus nulla at
                volutpat diam ut. Massa tempor nec feugiat nisl. Sed lectus
                vestibulum mattis ullamcorper. Elementum nibh tellus molestie
                nunc non blandit massa enim. Consectetur purus ut faucibus
                pulvinar elementum integer. Imperdiet nulla malesuada
                pellentesque elit eget gravida cum. Habitasse platea dictumst
                quisque sagittis purus sit amet. Purus semper eget duis at
                tellus at urna. Adipiscing elit ut aliquam purus sit amet.
                Egestas egestas fringilla phasellus faucibus. A iaculis at erat
                pellentesque adipiscing commodo elit.
              </p>
            </ScrollArea>
          </Paper>
        </Container>

        <Button
          mt={20}
          color={isValid() ? "teal" : "pink"}
          size="xl"
          onClick={() => onCheck()}
        >
          Accept terms
        </Button>
      </header>
    </div>
  );
}

export default S3;
