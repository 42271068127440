import { useState, useEffect } from "react";
import logo from "../rainbow.png";
import sound from "../gay.mp3";
import confetti from "../img/confetti.gif";
import "../App.css";

import { Button, Group } from "@mantine/core";

function Gay(props) {
  const [showButtons, setShowButtons] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      onGay();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGay = (n=1) => {
    try{
      const audio = document.getElementById("myAudio");
      audio.play();
    }
    catch{
      
    }
    window.gtag('event', `page_view`, {
      "step": `Gay ${n}`
    });
  };

  const noText = () => {
    let text = "No"
    if (props.counter < 4){
      for (let i = 0; i < props.counter; i ++){
        text += "o"
      }
    }
    else{
      text += `oX${props.counter}`
    }
    return text
  }

  return (
    <div className="App">
      <audio id="myAudio" controls style={{ display: "none" }}>
        <source src={sound} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={confetti} className="App-logo2" alt="logo" style={{position: "absolute", zIndex: 9999}} />
        <p>Congratulations, you are Gay!</p>
        {showButtons && (
          <Group>
            <Button
              color="pink"
              size="xl"
              onClick={() => setShowButtons(false)}
            >
              Hooray! I'm Gay 🌈
            </Button>
            <Button color="dark" size="xl" onClick={() => props.restart()}>
              {noText()}
            </Button>
          </Group>
        )}
      </header>
    </div>
  );
}

export default Gay;
