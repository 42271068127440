import { useState } from "react";
import "./App.css";
import Gay from "./components/Gay";
import S1 from "./components/S1";
import S2 from "./components/S2";
import S3 from "./components/S3";
import S4 from "./components/S4";
import S5 from "./components/S5";
import S6 from "./components/S6";
import S7 from "./components/S7";
import S8 from "./components/S8";
import S9 from "./components/S9";
import S10 from "./components/S10";
import S11 from "./components/S11";
import S12 from "./components/S12";

function App() {
  const [step, setStep] = useState(1);
  const [stepD, setStepD] = useState(1);
  const [gay, setGay] = useState(false);
  const [counter, setCounter] = useState(0)
  

  const next = () => {
    window.gtag('event', `page_view`, {
      "step": `Level ${step+1}`
    });
    setStep(step+1)
  };

  const nextD = () => {
    window.gtag('event', `page_view`, {
      "step": `Level D ${step+1}`
    });
    setStepD(stepD+1)
  };

  const restart = () => {
    setGay(false)
    // setStep(1)
  };

  const youGay = () => {
    window.gtag('event', `page_view`, {
      "step": `Gay Level ${counter+1}`
    });
    setCounter(counter+1)
    setGay(true);
  };

  return <>{gay ? <Gay restart={restart} counter={counter} /> : (
    <>
      {step === 1 && <S1 youGay={youGay} next={next}/> }
      {step === 2 && <S2 youGay={youGay} next={next}/> }
      {step === 3 && <S3 youGay={youGay} next={next}/> }
      {step === 4 && <S7 youGay={youGay} next={next}/> }
      {step === 5 && <S11 youGay={youGay} next={next}/> }
      {step === 6 && <S12 youGay={youGay} next={next}/> }
      {step === 7 && <S8 youGay={youGay} next={next}/> }
      {step === 8 && <S9 youGay={youGay} next={next}/> }
      {step === 9 && <S10 youGay={youGay} next={next}/> }
      {stepD ===1 && <S4 youGay={youGay} next={nextD}/> }
      {stepD === 2 && <S5 youGay={youGay} next={nextD}/> }
      {stepD === 3 && <S6 youGay={youGay} next={nextD}/> }
    </>
  )}</>;
}

export default App;
