import { useState } from "react";
import P1 from "../photos/p1.png";
import P2 from "../photos/p2.png";
import P3 from "../photos/p3.png";
import P4 from "../photos/p4.png";
import P5 from "../photos/p5.png";
import P6 from "../photos/p6.png";
import P7 from "../photos/p7.png";
import P8 from "../photos/p8.png";
import P9 from "../photos/p9.png";
import "../App.css";

import { Image, SimpleGrid, Text } from "@mantine/core";

import Swal from "sweetalert2";

function S11({next, youGay}) {
  const [selected, setSelected] = useState([]);

  const onSelect = (n) => {
    const newSelection = [...selected];
    if (selected.indexOf(n) < 0) {
      newSelection.push(n);
      setSelected(newSelection);
    }
    setTimeout(() => {
      if (newSelection.length === 4){
        Swal.fire("Good!", "You're no fool!", "success")
        next()
      }
    }, 500)
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>Captcha: Are you human? <br /> <Text size="sm" dimmed>Select the women</Text></p>
        <SimpleGrid cols={3}>
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius="md"
            src={P1}
            alt="So?"
            onClick={() => youGay()}
          />
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius={selected.indexOf(6) >= 0 ? "xl" : "md"}
            src={P6}
            alt="So?"
            onClick={() => onSelect(6)}
          />
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius="md"
            src={P3}
            alt="So?"
            onClick={() => youGay()}
          />
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius="md"
            src={P4}
            alt="So?"
            onClick={() => youGay()}
          />
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius={selected.indexOf(8) >= 0 ? "xl" : "md"}
            src={P8}
            alt="So?"
            onClick={() => onSelect(8)}
          />
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius="md"
            src={P2}
            alt="So?"
            onClick={() => youGay()}
          />
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius={selected.indexOf(7) >= 0 ? "xl" : "md"}
            src={P7}
            alt="So?"
            onClick={() => onSelect(7)}
          />
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius="md"
            src={P5}
            alt="So?"
            onClick={() => youGay()}
          />
          <Image
            width={100}
            height={100}
            fit={"fill"}
            mx="auto"
            radius={selected.indexOf(9) >= 0 ? "xl" : "md"}
            src={P9}
            alt="So?"
            onClick={() => onSelect(9)}
          />
        </SimpleGrid>
      </header>
    </div>
  );
}

export default S11;
