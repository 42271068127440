import { useState } from "react";

import "../App.css";

import { Button, TextInput } from "@mantine/core";


import Swal from 'sweetalert2'

function S2(props) {
  const [value, setValue] = useState("");

  const onCheck = () => {
    if (value === "") {
      props.youGay();
    } else {
      Swal.fire("Good!", "Struggle is the way", "success")
      props.next();
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <TextInput
          size="xl"
          placeholder="Write your username"
          label="Username"
          withAsterisk
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
        />
        <Button mt={20} color={value === "" ? "pink" : "teal"} size="xl" onClick={() => onCheck()}>
          {value === "" ? "Spare me the struggle" : "Continue"}
        </Button>
      </header>
    </div>
  );
}

export default S2;
